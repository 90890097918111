import { FormType } from '../models/FormTypes';
import { ClientFormTag, ClientFormUser } from '../models/ClientFormUser';
import { VersionState } from '../models/ClientFormVersionHistory';
import { simpleGlobalState } from './SimpleGlobalState';

type ActivityCommentsStats = {
  clientFormId: string;
  unreadCommentsCount: number;
  sectionCommentsCounts: Record<string, number>;
  actionCommentsCounts: Record<string, number>;
};

export const useCurrentFormType = simpleGlobalState<(typeof FormType)[keyof typeof FormType] | null>(null);

export const useCurrentFormVersionState = simpleGlobalState<VersionState>(VersionState.Draft);

export const useCurrentActivityCommentsStats = simpleGlobalState<ActivityCommentsStats>({
  clientFormId: '',
  unreadCommentsCount: 0,
  sectionCommentsCounts: {},
  actionCommentsCounts: {},
});

export const useCurrentFormUsers = simpleGlobalState<ClientFormUser[]>([]);

export const useFormSectionUsers = simpleGlobalState<ClientFormUser[]>([]);

export const useClientFormUserTags = simpleGlobalState<ClientFormTag[]>([]);

export const useFormCompletedSections = simpleGlobalState<Record<string, boolean>>({});

export const useHidePrivateComments = simpleGlobalState(false);

export const useViewDistributionLogs = simpleGlobalState(false);
